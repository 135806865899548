import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import {
  DeleteUser,
  FetchAllUserManagerList,
  FetchUserManagementList,
  InviteOrEditUser,
  ResendInviteUser,
} from '../actions/user-management.action';
import { tap } from 'rxjs';

interface IUserManagementStateModel {
  userManagementList: IUserManagementList[];
  userManagementListCount: number;
  allUserManagerList : IUserMangerList[]
  allUserManagerListCount : number
}
@State<IUserManagementStateModel>({
  name: 'userManagementState',
  defaults: {
    userManagementList: [],
    userManagementListCount: 0,
    allUserManagerList :[],
    allUserManagerListCount : 0
  },
})
@Injectable()
export class UserManagementState {
  constructor(private _http: HttpService, private _toastr: ToastrService) {}
  @Selector()
  static userManagementList(state: IUserManagementStateModel) {
    return state.userManagementList;
  }
  @Selector()
  static userManagementListCount(state: IUserManagementStateModel) {
    return state.userManagementListCount;
  }

  @Selector()
  static allUserManagerList(state: IUserManagementStateModel) {
    return state.allUserManagerList;
  }

   @Selector()
  static allUserManagerListCount(state: IUserManagementStateModel) {
    return state.allUserManagerListCount;
  }
  @Action(FetchUserManagementList)
  FetchUserManagementList(
    ctx: StateContext<IUserManagementStateModel>,
    { param }: FetchUserManagementList
  ) {
    return this._http.post('user/list', param).pipe(
      tap((apiResult) => {
        const result = apiResult.response.dataset;
        ctx.patchState({
          userManagementList: result.users,
          userManagementListCount: result.total_rows,
        });
      })
    );
  }
  @Action(InviteOrEditUser)
  InviteOrEditUser(
    ctx: StateContext<IUserManagementStateModel>,
    { param }: InviteOrEditUser
  ) {
    return this._http.post('user/inv-user', param).pipe(
      tap((apiResult: any) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }
  @Action(DeleteUser)
  DeleteUser(
    ctx: StateContext<IUserManagementStateModel>,
    { param }: DeleteUser
  ) {
    return this._http.post('user/delete', param).pipe(
      tap((apiResult: any) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }
  @Action(ResendInviteUser)
  ResendInviteUser(
    ctx: StateContext<IUserManagementStateModel>,
    { param }: ResendInviteUser
  ) {
    return this._http.post('user/resend-inv', param).pipe(
      tap((apiResult: any) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }


  @Action(FetchAllUserManagerList)
  FetchAllUserManagerList(ctx: StateContext<IUserManagementStateModel>, {param}:FetchAllUserManagerList) {
    return this._http.post('user/managers', param).pipe(
      tap((apiResult) => {
        ctx.patchState({ 
          allUserManagerList: apiResult.response.dataset.users,
           allUserManagerListCount : apiResult.response.dataset.total_rows
        });
      })
    );
  }
}
