export class FetchUserManagementList {
  static readonly type = '[FetchUserManagementList] Post';
  constructor(public param: IFetchUserManagementListParam) {}
}
export class InviteOrEditUser {
  static readonly type = '[InviteOrEditUser] Post';
  constructor(public param: IInviteOrEditUserParam) {}
}
export class DeleteUser {
  static readonly type = '[DeleteUser] Post';
  constructor(public param: IDeleteUserParam) {}
}
export class ResendInviteUser {
  static readonly type = '[ResendInviteUser] Post';
  constructor(public param: IResendInviteParam) {}
}
export class FetchAllUserManagerList {
  static readonly type = '[FetchAllUserManagerList] Post';
   constructor(public param:IFetchAllUserManagerListParam) {}
}

